import Image from '@next/image';

import { Testimonial as TestimonialType } from 'types/models/Testimonial';
import Typography from '@components/atoms/Typography';

import classes from './Testimonial.module.scss';

type Props = Pick<TestimonialType, 'personName' | 'companyName' | 'text' | 'image'>;

const Testimonial = ({ companyName, personName, text, image }: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <div className={classes.imageWrapper}>
      <Image
        layout="fill"
        className={classes.image}
        alt={`${companyName} - ${personName}`}
        resolveConfig={{
          preset: 'testimonial',
          productName: companyName,
          extension: image.extension,
          name: image.basename,
          description: personName,
        }}
        hasBlendMode={false}
      />
    </div>
    <div className={classes.description}>
      <Typography variant="h3" weight="400" renderAs="i">
        {text}
      </Typography>
    </div>
    <div className={classes.author}>
      <Typography variant="h3" display="block" renderAs="div" className={classes.subtitle}>
        {companyName}
      </Typography>
      <Typography variant="body2" display="block">
        {personName}
      </Typography>
    </div>
  </div>
);

export default Testimonial;
