import { useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

import { Testimonial as TestimonialType } from 'types/models/Testimonial';
import CrossIcon from 'assets/icons/cross.svg';
import Slider from '@templates/Slider';
import Modal from '@components/Modal';
import Arrow from '@templates/Slider/components/Arrow';
import Typography from '@components/atoms/Typography';
import { getPlayTestimonialVideoPayload } from 'utils/analytics/payload/playTestimonialVideo';
import resolveFileUrl from 'utils/resolveFileUrl';
import defer from 'utils/defer';
import useTrack from 'hooks/useTrack';
import useBoolean from 'hooks/useBoolean';

import Testimonial from './components/Testimonial';
import SliderDots from './components/SliderDots';
import classes from './ClientsAboutUs.module.scss';

export type Props = {
  testimonials: Pick<TestimonialType, 'id' | 'companyName' | 'personName' | 'text' | 'image' | 'video'>[];
};

const ClientsAboutUs = ({ testimonials }: Props): JSX.Element => {
  const { trackEvent } = useTrack();
  const [isModalOpened, { on: openModal, setValue: setIsModalOpened }] = useBoolean();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [modalSlideIndex, setModalSlideIndex] = useState(0);
  const { ref, inView } = useInView({ triggerOnce: true });
  const videoRef = useRef<HTMLVideoElement>(null);
  const currentModalTestimonial = testimonials[modalSlideIndex];

  return (
    <div ref={ref} className={classes.sliderWrapper}>
      {inView && (
        <Slider
          key="slider"
          className={classes.slider}
          dots={({ currentSlide, ...props }) => (
            <SliderDots {...props} currentSlide={currentSlide} key={currentSlide} items={testimonials} />
          )}
          selectedSlideIndex={currentSlideIndex}
          onSlideChange={(index) => {
            if (!isModalOpened) {
              setModalSlideIndex(index);
            }
          }}
          withArrows
          withDots
          lazyLoad
          autoPlay
        >
          {testimonials.map(({ id, companyName, personName, text, image }) => (
            <div key={id} className={classes.slide} onClick={openModal}>
              <Testimonial companyName={companyName} personName={personName} text={text} image={image} />
            </div>
          ))}
        </Slider>
      )}
      <Modal
        size={Modal.size.big}
        controlledIsOpen={isModalOpened}
        onOpenChange={(value) => {
          setIsModalOpened(value);

          if (!value) {
            setCurrentSlideIndex(modalSlideIndex);
          }
        }}
        hideWhenClickOutside
        withoutHeader
        transparent
      >
        {({ close }) => (
          <div className={classes.modalContent}>
            <div className={classes.modalHeader}>
              <Typography variant="h1" renderAs="div" weight="400" className={classes.modalTitle}>
                {`${currentModalTestimonial.companyName} - ${currentModalTestimonial.personName}`}
              </Typography>
              <div className={classes.close} onClick={close}>
                <CrossIcon className={classes.closeIcon} />
              </div>
            </div>
            <Slider
              key="video-slider"
              className={classes.videoSlider}
              slideClassName={classes.videoSlide}
              selectedSlideIndex={modalSlideIndex}
              leftArrow={({ disabled, onClick }) => (
                <div className={classNames(classes.modalArrow, classes.leftArrow)} onClick={onClick}>
                  <Arrow width={14} variant="left" disabled={disabled} rawIcon />
                </div>
              )}
              rightArrow={({ disabled, onClick }) => (
                <div className={classNames(classes.modalArrow, classes.rightArrow)} onClick={onClick}>
                  <Arrow width={14} variant="right" disabled={disabled} rawIcon />
                </div>
              )}
              onSlideChange={(index) => {
                videoRef.current?.pause();
                setModalSlideIndex(index);
                defer(async () => {
                  try {
                    await videoRef.current?.play();
                    trackEvent(
                      'common',
                      'play_testimonial_video',
                      getPlayTestimonialVideoPayload(testimonials[index].companyName)
                    );
                  } catch (e) {} // eslint-disable-line no-empty
                });
              }}
              withArrows
              lazyLoad
            >
              {testimonials.map(({ id, video }, i) => (
                <div key={id} className={classes.videoWrapper}>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video ref={i === modalSlideIndex ? videoRef : undefined} preload="metadata" controls>
                    <source src={`${resolveFileUrl(video)}#t=0.5`} type="video/mp4" />
                  </video>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ClientsAboutUs;
